import React from "react";
import {useNavigate} from 'react-router-dom';


const Offers = () => {
  const navigate = useNavigate();
  return (
    <div>

<h1 onClick={() => navigate('/Create')}>Create offers</h1>

<h1>edit offers</h1>
    </div>

  );
};

export default Offers;
