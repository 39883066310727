import React, { useEffect, useState } from "react";
import Layout from "./../components/Layout/Layout";
import { useParams } from "react-router-dom";
import { db } from "./../firebase.config";
import { toast } from "react-toastify";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import Spinner from "../components/Spinner";
import ListingItem from "../components/ListingItem";
import TypeWordsItem from "../components/TypeingWords"; // Adjust the import path
import styles from "./category.module.css";

const Category = () => {
  const [listing, setListing] = useState([]);
  const [typeWords, setTypeWords] = useState([]);
  const [lastFetchListing, setLastFetchListing] = useState(null);
  const [lastFetchTypeWords, setLastFetchTypeWords] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newTypewordEng, setNewTypewordEng] = useState("");
  const [newTypewordIta, setNewTypewordIta] = useState("");
  const params = useParams();

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const listingsRef = collection(db, "listings");
        const q = query(
          listingsRef,
          where("type", "==", params.categoryName),
          orderBy("timestamp", "desc"),
          limit(1)
        );
        const querySnap = await getDocs(q);
        const lastVisible = querySnap.docs[querySnap.docs.length - 1];
        setLastFetchListing(lastVisible);
        const listingsData = querySnap.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        setListing(listingsData);
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("Unable to fetch data");
      }
    };

    fetchListing();
  }, [params.categoryName]);

  const fetchLoadMoreListing = async () => {
    try {
      const listingsRef = collection(db, "listings");
      const q = query(
        listingsRef,
        where("type", "==", params.categoryName),
        orderBy("timestamp", "desc"),
        startAfter(lastFetchListing),
        limit(10)
      );
      const querySnap = await getDocs(q);
      const lastVisible = querySnap.docs[querySnap.docs.length - 1];
      setLastFetchListing(lastVisible);
      const listingsData = querySnap.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      setListing((prevState) => [...prevState, ...listingsData]);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Unable to fetch data");
    }
  };

  useEffect(() => {
    const fetchTypeWords = async () => {
      try {
        const typeWordsRef = collection(db, "typeWords");
        const q = query(
          typeWordsRef,
          orderBy("timestamp", "desc"),
          limit(10) // Adjust the limit as needed
        );
        const querySnap = await getDocs(q);
        const typeWordsData = querySnap.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        setTypeWords(typeWordsData);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch typewords");
      }
    };

    fetchTypeWords();
  }, []);

  const fetchLoadMoreTypeWords = async () => {
    try {
      const typeWordsRef = collection(db, "typeWords");
      const q = query(
        typeWordsRef,
        orderBy("timestamp", "desc"),
        startAfter(lastFetchTypeWords),
        limit(10)
      );
      const querySnap = await getDocs(q);
      const lastVisible = querySnap.docs[querySnap.docs.length - 1];
      setLastFetchTypeWords(lastVisible);
      const typeWordsData = querySnap.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      setTypeWords((prevState) => [...prevState, ...typeWordsData]);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Unable to fetch data");
    }
  };

  const handleTypewordSubmit = async (e) => {
    e.preventDefault();

    const newTypeword = {
      eng: newTypewordEng,
      ita: newTypewordIta,
      // Add other properties if needed...
    };

    try {
      await db.collection("typeWords").add(newTypeword);
      setTypeWords((prevTypeWords) => [...prevTypeWords, { data: newTypeword }]);
      setNewTypewordEng("");
      setNewTypewordIta("");
      toast.success("Typeword created successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to create typeword");
    }
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div>
          <h1>
            {params.categoryName === "blog" ? "Places For blog" : "Plces For Sale"}
          </h1>
          {loading ? (
            <Spinner />
          ) : listing && listing.length > 0 ? (
            <div>
              {listing.map((list) => (
                <ListingItem listing={list.data} id={list.id} key={list.id} />
              ))}
            </div>
          ) : (
            <p>No Listing For {params.categoryName} </p>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-center mb-4 mt-4">
          {lastFetchListing && (
            <button className={styles.btn} onClick={fetchLoadMoreListing}>
              Load more
            </button>
          )}
        </div>
      </div>

      <div className={styles.container}>
       
        <div>
          <h2>Typewords</h2>
          {typeWords.length > 0 ? (
            <ul>
              {typeWords.map((typeWord) => (
                <TypeWordsItem
                  typeWord={typeWord.data}
                  id={typeWord.id}
                  key={typeWord.id}
                />
              ))}
            </ul>
          ) : (
            <p>No Typewords Available</p>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-center mb-4 mt-4">
          {lastFetchTypeWords && (
            <button className={styles.btn} onClick={fetchLoadMoreTypeWords}>
              Load more
            </button>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Category;
