import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { getAuth, updateProfile } from "firebase/auth";
import { db } from "../firebase.config";
import { FaEdit, FaArrowAltCircleRight } from "react-icons/fa";
import { MdDoneOutline } from "react-icons/md";
import {
  doc,
  updateDoc,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import ListingItem from "../components/ListingItem";
import TypeWordsItem from "../components/TypeingWords"; // Import TypeWordsItem
import styles from "./profile.module.css";


const Profile = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [listings, setListings] = useState(null);
  const [typeWords, setTypeWords] = useState(null);

  useEffect(() => {
    const fetchUserListings = async () => {
      const listingRef = collection(db, "listings");
      const q = query(
        listingRef,
        where("useRef", "==", auth.currentUser.uid),
        orderBy("timestamp", "desc")
      );
      const querySnap = await getDocs(q);
      let listings = [];
      querySnap.forEach((doc) => {
        listings.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      setListings(listings);
    };
    fetchUserListings();
  }, [auth.currentUser.uid]);

  useEffect(() => {
    const fetchUserTypeWords = async () => {
      const typeWordsRef = collection(db, "typeWords");
      const q = query(
        typeWordsRef,
        where("useRef", "==", auth.currentUser.uid),
        orderBy("timestamp", "desc")
      );
      const querySnap = await getDocs(q);
      let typeWords = [];
      querySnap.forEach((doc) => {
        typeWords.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      setTypeWords(typeWords);
      setLoading(false);
    };
    fetchUserTypeWords();
  }, [auth.currentUser.uid]);

  const [changeDetails, setChangeDetails] = useState(false);
  const [formData, setFormData] = useState({
    name: auth.currentUser.displayName,
    email: auth.currentUser.email,
  });
  const { name, email } = formData;

  const logoutHandler = () => {
    auth.signOut();
    toast.success("Successfully Logout");
    navigate("/");
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async () => {
    try {
      if (auth.currentUser.displayName !== name) {
        await updateProfile(auth.currentUser, {
          displayName: name,
        });
        const userRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(userRef, { name });
        toast.success("User Updated!");
      }
    } catch (error) {
      console.log(error);
      toast("Something Went Wrong");
    }
  };

  const onDelete = async (listingId) => {
    if (window.confirm("Are You Sure you want to delete?")) {
      await deleteDoc(doc(db, "listings", listingId));
      const updatedListings = listings.filter(
        (listing) => listing.id !== listingId
      );
      setListings(updatedListings);
      toast.success("Listing Deleted Successfully");
    }
  };
  const onWordsDelete = async (typeWordId) => {
    if (window.confirm("Are You Sure you want to delete?")) {
      await deleteDoc(doc(db, "typeWords", typeWordId));
      const updatedTypeWords = typeWords.filter(
        (typeWord) => typeWord.id !== typeWordId
      );
      setTypeWords(updatedTypeWords);
      toast.success("typeWord Deleted Successfully");
    }
  };
  return (

    <div className={styles.container}>
      <div className={styles.innerBox}>
        <div>
          <h4>Profile Details</h4>
          <div className={styles.logout}>
            <button className={styles.btn} onClick={logoutHandler}>
              Logout
            </button>
          </div>
        </div>
        <div className="container mt-4 card" >
          <div className="card-header">
            <div className="d-flex justify-content-between">
              <p>User Personal Details </p>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  changeDetails && onSubmit();
                  setChangeDetails((prevState) => !prevState);
                }}
              >
                {changeDetails ? (
                  <MdDoneOutline color="green" />
                ) : (
                  <FaEdit color="red" />
                )}
              </span>
            </div>
          </div>
          <div className="card-body">
            <form>
              <div className={styles.inputcontainer}>
                <label htmlFor="exampleInputPassword1" className={styles.heading}>
                  Name
                </label>
                <input name="text" 
                  type="text"
                  className={styles.inputfild}
                  id="name"
                  value={name}
                  onChange={onChange}
                  disabled={!changeDetails}
                />
              </div>
              <div className={styles.inputcontainer}>
                <label htmlFor="exampleInputEmail1" className={styles.heading}>
                  Email address
                </label>
                <input
                  type="email"
                  value={email}
                  className={styles.inputfild}
                  id="email"
                  aria-describedby="emailHelp"
                  onChange={onChange}
                  disabled={!changeDetails}
                />
              </div>
            </form>
          </div>
        </div>
        <div className={styles.blogs_list}>
          <Link to="/createListing" className={styles.Create_btn}>
            <FaArrowAltCircleRight color="#fff" /> Create New Blogs
          </Link>
        </div>
          <div className={styles.listing_blog}>
          {listings && listings?.length > 0 && (
            <>
              <h6>Your Blogs</h6>
              <div className={styles.listingblog}>

                {listings.map((listing) => (
                  <ListingItem
                    key={listing.id}
                    listing={listing.data}
                    id={listing.id}
                    onDelete={() => onDelete(listing.id)}

                  />
                ))}
              </div>
            </>
          )}
        </div>
        <div className={styles.blogs_list}>
          <Link to="/createTypeWords" className={styles.Create_btn}>
            <FaArrowAltCircleRight color="#fff" /> Create Type Words
          </Link>
        </div>
        <div className={styles.listing_blog}>
        {typeWords && typeWords?.length > 0 && (
          <>
            <h6>Your TypeWords</h6>
            <div className={styles.typeWordsList}>
              {typeWords.map((typeWord) => (
                <TypeWordsItem
                  key={typeWord.id}
                  typeWord={typeWord.data}
                  id={typeWord.id}
                  onWordsDelete={() => onWordsDelete(typeWord.id)}
                />
              ))}
            </div>
          </>
        )}
      </div>

      </div>
    </div>

  );
};

export default Profile;
