import React from "react";
import { Link } from "react-router-dom";
import styles from "./header.module.css";

const Header = () => {
  return (
    <>
      <nav className={styles.container}>
        <div className="container">
          <Link className={styles.all_bolgs} to="/homepage">
             Bloging Page
          </Link>

          <Link className={styles.profile} to="/profile">
            Profile
          </Link>
        </div>
      </nav>
    </>
  );
};

export default Header;
