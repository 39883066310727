import React from "react";
import { Link } from "react-router-dom";
import styles from "./listingitem.module.css";

const TypeWordsItem = ({ typeWord, id,  onWordsDelete }) => {
  return (
    <>
      <div className={styles.container_typewords}>
        <div className={styles.container_box}>
          <div>
            <div className={styles.btns}>
              {/* <div className={styles.btnbox1}>
                <Link
                  to={"/editWordsEditListing"+`/${id}`}
                  className={styles.btn}
                >
                  Edit
                </Link>
              </div> */}
              <div className={styles.btnbox2}>
                { onWordsDelete && (
                  <button
                    className={styles.btn}
                    onClick={() => onWordsDelete(typeWord.id)}
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
            <div
              className={styles.textbox}
            >
              <div className={styles.container_item}>
                <div className={styles.text_box}>
                  <h2 className={styles.no_underlin}>English - {typeWord.eng}</h2>
                  <h2 className={styles.no_underlin}>Italian - {typeWord.ita}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TypeWordsItem;

