import React, { useState } from "react";
import styles from "./about-us.module.css";
import { IoArrowForwardOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import Slider from "../../blog/components/Slider";




const AboutUSFirst = () => {
  const [searchParams] = useSearchParams();
  searchParams.get("token")
  console.log(searchParams.get("token"))
  const token = searchParams.get("token")
  const navigate = useNavigate();

  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const toggleBottomComponent = () => { setIsComponentVisible(!isComponentVisible) }

  return (
    <div>

      <div className={styles.component} id="about">

        <div className={styles.introComponent}>

          <h2>
          Implementa ODOO ERP per centralizzare la gestione dei tuoi reparti e accelerare la crescita
          </h2>
          <div className={styles.underline} />
          <div className={styles.description}>
            <p>ODOO è un ERP Open source usato da più di 12 milioni di utenti e implementato da più di 50.000 aziende provenienti da più di 200 nazioni.
              <br />
              Con 19 uffici (di cui uno a Milano), più di 5000 partner ufficiali (di cui 44 in Italia) e una comunità di sviluppo che supera i 100.000 membri…
              <br />
              ODOO si pone come alternativa flessibile e conveniente ma con un livello qualitativo alto.
              <br /> <br />
              ODOO offre una suite di 84 moduli perfettamente integrati per gestire ogni reparto e processo della tua PMI.
              <br />
              Dalla contabilità alla gestione del magazzino, dalle risorse umane al sito web, dal CRM alla produzione: Odoo permette a tutto il tuo personale di svolgere le attività chiave senza bisogno di software aggiuntivi.
              <br />
              E se gestisci più aziende, la sua funzionalità multicompany consente di amministrarle tutte da un'unica piattaforma.
              <br /> <br />
              Odoo è flessibile e personalizzabile. Poiché è un software open-source, il suo codice sorgente è disponibile a tutti e può essere modificato senza limiti.
              <br />
              Potrai quindi personalizzare Odoo per adattarlo alle esigenze dei tuoi reparti e integrarlo a portali esterni e software verticali che utilizzi già. 
              <br />
              Eliminerai così tediose attività di data entry e ottimizzerai il lavoro senza spendere una fortuna.
              </p>
          </div>
          {
            <div className={styles.offers}>
            <a href="/contact" className={styles.acontactButton} >
              <button className={styles.contactButton}  >
               Contattaci{" "}
                <IoArrowForwardOutline
                  color="#fff"
                  size={18}
                  style={{ marginLeft: 10 }}
                />
              </button></a>
            </div>
          }
        </div>
        {
          isComponentVisible && (

            <div className={styles.slider_container}>
              <Slider className={styles.slider} />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default AboutUSFirst;
