import React from "react";
import { useMedia } from "../../helper/usemedia";

import styles from "./e-commerce.module.css";

const data = [
  {
    title: 'Sito internet',
    text: 'Come comunica attraverso il suo sito? Può essere un indizio su come porterà a termine il lavoro per voi.',
  },
  {
    title: 'Case History',
    text: 'Ci sono dei lavori che catturano la vostra attenzione?',
  },
  {
    title: 'Rapporti umani',
    text: 'Vi sentite ascoltati nelle vostre richieste o ricevete in risposta bellissime frasi ma completamente scollegate dalla vostra domanda? \n Avete spazio per potervi esprimere liberamente?',
  },
  {
    title: 'Costo del progetto',
    text: 'Vi fa un prezzo su 2 piedi giusto per convincervi a firmare al momento o si prende i suoi tempi per valutare le vostre richieste con il tecnico?',
  },
];

const Ecommerce = () => {
  const isMobile = useMedia(600);
  return (
    <div>
      <div className={styles.videoComponent}>
        <video id={styles.background} autoPlay muted>
          <source src="/videos/video-4.mp4" type="video/mp4" />
        </video>
      </div>
      <div className={styles.aboutecommerce}>
        <div>
          <div>
            <p>Chi di voi non ha cercato una web agency su internet?</p>
            <br />
            <p>
              E chi di voi non è stato catturato dal messaggio pubblicitario
              «tutto incluso a 99 euro»?
            </p>
            <br />
            <p>
              Ebbene si, di messaggi che inquinano letteralmente il mercato
              lasciando il cliente insoddisfatto ce ne sono molti.
            </p>
            <br />
            <p>
              Personalmente faccio consulenza dal 2008 in svariate realtà e non
              ho mai trovato un cliente che, nonostante producesse o
              commercializzasse gli stessi prodotti, avesse più di una virgola
              in comune con un altro.
            </p>
            <br />
            <p>
              Ogni azienda ha le sue necessità, come è vero che ogni essere
              umano è unico e raro.
            </p>
            <br />
            <p>
              Ogni azienda ha la sua peculiarità come ogni essere umano ha i
              suoi talenti: se non sono in grado di mettere in risalto i miei
              talenti ho già fallito nel cercare di esprimere il mio vero
              essere.
            </p>
            <br />
            <br />
          </div>
          <div className={isMobile ? styles.mobileComponent : styles.component}>
      
          <div
            className={
              isMobile ? styles.mobileInnerComponent : styles.innerComponent
            }
          >
          <h2>Consigli per scegliere un professionista</h2>
          <div className={styles.underline} />
            <div className={ styles.cardsComponent}>
              {data.map((item) => {
                return (
                  <div
                    className={
                      isMobile ? styles.mobileCardComponent : styles.cardComponent
                    }
                  >
                    <div className={styles.redCircle} />
                    <div
                      className={
                        isMobile ? styles.mobileDescription : styles.description
                      }
                    >
                      <h2>{item.title}</h2>
                      <p>{item.text}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Ecommerce;
