import React from "react";
import {useNavigate} from 'react-router-dom';
import { IoArrowForwardOutline } from "react-icons/io5";

import { useMedia } from "../../../helper/usemedia";
import styles from "./bannerforaboutus.module.css";

const Bannerforaboutus = () => {
  const isMobile = useMedia(1000);

  const navigate = useNavigate();

  const WebComponent = () => {
    return (
      <div className={styles.innerComponent}>
        <div className={styles.block_1}>
          <div>
            <div className={styles.block_1_name_component}>
              <div className={styles.block_1_name_inner_component}>
                <div>
                 <h1>
                  Implementa <span>ODOO</span> ERP per unificare la gestione dei tuoi reparti
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.block_2}>
          <img src="/images/b3.jpg" alt="business consultency banner" />
        </div>
      </div>
    );
  };

  const MobileComponent = () => {
    return (
      <div className={styles.mobileComponent}>
        <div className={styles.mobileHeadingComponent}>
        <h1>
        Implementa Odoo ERP per unificare la gestione dei tuoi reparti	
        </h1>
        

         
        </div>
        <div className={styles.mobileImageComponent}>
          <img src="/images/b3.jpg" alt="business consultency banner" />
        </div>
        <div className={styles.mobileNameComponent}>

        
        </div>
      </div>
    );
  };
  return (
    <div className={styles.rootComponent}>
      {isMobile ? <MobileComponent /> : <WebComponent />}
    </div>
  );
};

export default Bannerforaboutus;
