import { Navigate, Outlet } from "react-router-dom";

import React from "react";
import useAuthState from "./../hooks/useAuthState";
import Spinner from "./Spinner";

const PrivateRoute = ({children}) => {
  const { loggedIn, checkState } = useAuthState();

  if (checkState) {
    return <Spinner />;
  }

  return loggedIn ? children ? children : <Outlet />: <Navigate to="/signin" />;
};

export default PrivateRoute;
