import React from "react";
import { Link } from "react-router-dom";
import styles from "./listingitem.module.css";

const ListingItem = ({ listing, id, onDelete }) => {
  return (
    <>
      <div className={styles.container_lis}>
        <div className={styles.container_box} >
          <div >
          <div className={styles.btns}><div className={styles.btnbox1}>
            <Link to={"/editListing" + `/category/${listing.type}/${id}`}
            className={styles.btn}
            >Edit
            </Link></div>
            <div className={styles.btnbox2}>
              {onDelete && (
                <button
                  className={styles.btn}
                  onClick={() => onDelete(listing.id)}
                >
                  Delete Listing
                </button>
              )}
            </div></div>
            <Link to={`/category/${listing.type}/${id}`} className={styles.textbox}>
              <div className={styles.container_item}>
                <div className={styles.image} >
                  <img
                    src={listing.imgUrls[0]}

                    alt={listing.name}

                  />
                </div>
                <div className={styles.text_box}>
                  <h2 className={styles.no_underlin} >{listing.name}</h2>
                  <p className={styles.no_underlin}>{listing.body}</p>
                </div>
              </div>
            </Link>
          </div>

        </div>
      </div>
    </>



  );
};

export default ListingItem;
