import React, { useState, useEffect } from "react";
import { db } from "../firebase.config";
import {
  collection,
  getDoc,
  query,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import SwipeCore, { EffectCoverflow, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Spinner from "./Spinner";
import styles from "./slider.module.css";
import "./slider.css"

//config
SwipeCore.use([EffectCoverflow, Pagination]);

const Slider = () => {
  const [listings, setListings] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigat = useNavigate();
  const userPic =
    "https://openclipart.org/download/247319/abstract-user-flat-3.svg";

  useEffect(() => {
    const fetchListings = async () => {
      const listingRef = collection(db, "listings");
      const q = query(listingRef, orderBy("timestamp", "desc"), limit(5));
      const querySnap = await getDocs(q);
      let listings = [];
      querySnap.forEach((doc) => {
        return listings.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      setLoading(false);
      setListings(listings);
    };
    fetchListings();
    console.log(listings === null ? "loading" : listings);
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <div className={styles.container}>
        {listings === null ? (
          <Spinner />
        ) : (
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={1}
            coverflowEffect={{
              rotate: 100,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            // pagination={true}
            navigation={true} modules={[Navigation]}
            style={{
            '--swiper-navigation-color': '#fff',
            '--swiper-navigation-size': "30px",
            '--swiper-navigation-padding': "30px",
            '--swiper-navigation-background-color': "#1cc1b4",
             }}
            className="mySwipe"
          >
            {listings.map(({ data, id }) => (
            <div className={styles.container_box} >
              <div className={styles.card}>
               <SwiperSlide
                key={id}
                onClick={() => {
                  navigat(`/category/${data.type}/${id}`);
                }}> 
                <div class={styles.contant_card} >
                <div class={styles.contant_bx} >
              <div class={styles.imgbx} >
                
                <img
                  src={data.imgUrls[0]}
                  height={400}
                  width={1250}
                  alt={data.name}
                /></div>
                <div className={styles.text}>
                  <h1 >
                    <span className="ms-2"> {data.name}</span>
                  </h1>
                  <p ><pre>{data.body}</pre></p>
                  </div>
                  </div>
                  </div>
              </SwiperSlide></div></div> 
            ))}
          </Swiper>
        )}
      </div>
    </>
  );
};

export default Slider;