import "rsuite/styles/index.less";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./App.css";
import "@fontsource/balsamiq-sans";
import AppLayout from "./AppLayout";
import Home from "./components/home";
import AboutUS from "./components/about-us";
import Contact from "./components/contact";
import ConsulenzaAziendale from "./components/consulenza-aziendale";
import Termsconditions from "./components/terms-conditions";
import Shopify from "./components/shopify";
import Ecommerce from "./components/e-commerce";
import Offers from "./components/about-us/Offers";
import Login from "./components/login/Login/Login";
import Signin from "./components/blog/pages/Signin";
import CreateListing from "./components/blog/pages/CreateListing";
import HomePage from "./components/blog/pages/HomePage";
import Profile from "./components/blog/pages/Profile";
import Signup from "./components/blog/pages/Signup";
import Category from "./components/blog/pages/Category";
import Listing from "./components/blog/pages/Listing";
import PrivateRoute from "./components/blog/components/PrivateRoute";
import EditListing from "./components/blog/pages/edit";
import CreateTypeWords from "./components/blog/pages/CreateTypeWords";
import TypeWordsEditListing from "./components/blog/pages/WordsEditListing";

function App() {
  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <AppLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/aboutus",
          element: <AboutUS />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "/consulenza-aziendale",
          element: <ConsulenzaAziendale />,
        },
        {
          path: "/terms-conditions",
          element: <Termsconditions />,
        },
        {
          path: "/shopify",
          element: <Shopify />,
        },
        {
          path: "/ecommerce",
          element: <Ecommerce />,
        },
        {
          path: "/Offers",
          element: <Offers />,
        },

        {
          path: "/login",
          element: <Login />,
        },

        {
          path: "/signin",
          element: <Signin />,
        },
        {
          path: "/createListing",
          element: <CreateListing />,
        },
        {
          path: "/CreateTypeWords",
          element: <CreateTypeWords/>
        },

        {
          path: "/signup",
          element: <Signup />,
        },
        {
          path: "/category/:categoryName",
          element: <Category />,
        },

        {
          path: "/homepage",
          element: <HomePage />,
        },

        {
          path: "/profile",
          element: (
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          ),
        },
        {
          path: "/category/:categoryName/:listingId",
          element: <Listing />,
        },
      
        {
          path:"/editListing/:category/:categoryName/:listingId",
          element:<EditListing/>
        },
        {
          path:"/editWordsEditListing/:Id",
          element:<TypeWordsEditListing/>,
        },
      ],
    },
  ]);
  return <RouterProvider router={appRouter} />;
}

export default App;
