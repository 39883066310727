import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDAsFecfPt93UpiI1gy5n9szmSNFS80U24",
  authDomain: "consulux-66661.firebaseapp.com",
  projectId: "consulux-66661",
  storageBucket: "consulux-66661.appspot.com",
  messagingSenderId: "764719532382",
  appId: "1:764719532382:web:0f77241264cbfe6a9aa7cf"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth();

export { app, auth };
