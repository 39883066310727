import React from "react";
// import { Link } from "react-router-dom";
import { IoLogoLinkedin } from "react-icons/io5";
// import { Modal } from "rsuite";
// import Typewriter from 'typewriter-effect';
import styles from "./my-story.module.css";
import { useMedia } from "../../../../helper/usemedia";
const Mystory = () => {
  const isMobile = useMedia(600);

  if (isMobile) {
    return ( 
      <div className={styles.mobileComponent}>
        <div className={styles.mobileInnerComponent}>
          <div className={styles.mobileImageComponent}>

          </div>
          <div className={styles.mystoryright}>

            <h1>
              <span>La nostra mission</span>
            </h1>
            <div className={styles.underline} />
            <div  className={styles.descriptionComponent}>
             
            <p>
            Consulux nasce in provincia di Varese nel 2021 dalla voglia di collaborare tra più professionisti. Ben presto
             si dimostra essere un'idea vincente che unisce conoscenza ed esperienza in maniera multiculturale.
             <br /> <br />
            Il nostro team è strutturato per supportare e consigliare aziende medie e piccole nell'aggiornamento dei flussi aziendali e nell'implementazione di soluzioni informatiche all'avanguardia.
            <br />
            •	Forniamo indicazioni e approfondimenti alle imprese per supportare le strategie e-commerce e ricercare nuove opportunità di crescita e di vendita (Google Ads, Social, Marketing)
            <br />
            •	Supportiamo il cliente nell'implementazione di ERP (Microsoft Business Central, Odoo, Infor CloudSuite) e l'analisi dei flussi aziendali 
            <br />
            •	Creiamo applicazioni fruibili da web e da mobile per essere sempre più responsivi alle necessità del cliente
            <br />
            • Ci dedichiamo a costruire applicazioni che consentono l'integrazione di funzionalità fra più sistemi (come ad es. WMS o MES che vanno a dialogare con un ERP esistente)
            <br /> 
            Collaboriamo con i nostri clienti per determinare le soluzioni e applicazioni necessarie per il successo e, se quanto
            offre il mercato informatico non è sufficiente, ci impegnamo a costruire soluzioni "ad hoc" per rispondere alle esigenze dei nostri clienti.
            <br /> <br />
            Le parole chiave in Consulux sono:
            <br /> 
            - ascolto empatico delle necessità del cliente
            <br /> 
            - sviluppo di soluzioni su misura
            <br /> 
            - formazione e consulenza continua
            <br /> 
            - supporto professionale
          </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
<div className={styles.mystoryright}>
<h1>
          <span>La nostra mission</span>
          </h1>
          <div className={styles.underline} />
        <div className={styles.descriptionComponent}>
          
        <p>
            Consulux nasce in provincia di Varese nel 2021 dalla voglia di collaborare tra più professionisti. Ben presto
             si dimostra essere un'idea vincente che unisce conoscenza ed esperienza in maniera multiculturale.
             <br /> <br />
            Il nostro team è strutturato per supportare e consigliare aziende medie e piccole nell'aggiornamento dei flussi aziendali e nell'implementazione di soluzioni informatiche all'avanguardia.
            <br />
            •	Forniamo indicazioni e approfondimenti alle imprese per supportare le strategie e-commerce e ricercare nuove opportunità di crescita e di vendita (Google Ads, Social, Marketing)
            <br />
            •	Supportiamo il cliente nell'implementazione di ERP (Microsoft Business Central, Odoo, Infor CloudSuite) e l'analisi dei flussi aziendali 
            <br />
            •	Creiamo applicazioni fruibili da web e da mobile per essere sempre più responsivi alle necessità del cliente
            <br />
            • Ci dedichiamo a costruire applicazioni che consentono l'integrazione di funzionalità fra più sistemi (come ad es. WMS o MES che vanno a dialogare con un ERP esistente)
            <br /> 
            Collaboriamo con i nostri clienti per determinare le soluzioni e applicazioni necessarie per il successo e, se quanto
            offre il mercato informatico non è sufficiente, ci impegnamo a costruire soluzioni "ad hoc" per rispondere alle esigenze dei nostri clienti.
            <br /> <br />
            Le parole chiave in Consulux sono:
            <br /> 
            - ascolto empatico delle necessità del cliente
            <br /> 
            - sviluppo di soluzioni su misura
            <br /> 
            - formazione e consulenza continua
            <br /> 
            - supporto professionale
          </p>
          </div>
      </div>
  );
};

export default Mystory;
