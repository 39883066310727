import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import Layout from "./../components/Layout/Layout";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import styles from "./createListing.module.css";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { db } from "../firebase.config";
import {
  addDoc,
  collection,
  serverTimestamp,
  updateDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import { useParams } from "react-router-dom";

const TypeWordsEditListing = () => {
  const [typeWord, setTypeWord] = useState("");
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    // type: "Type Words",
    eng: "",
    ita: "",
  });

  const { eng, ita } = formData;

  const auth = getAuth();
  const navigate = useNavigate();
  const isMounted = useRef(true);

  useEffect(() => {
    const fetchTypeWord = async () => {
      const docRef = doc(db, "TypeWords", params.typeWordId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log(docSnap.data());
        setFormData((prev) => ({
          ...prev,
          eng: docSnap.data()?.eng,
          ita: docSnap.data()?.ita,
        }));
        setTypeWord(docSnap.data());
        setLoading(false);
      }
    };
    fetchTypeWord();
  }, [params.typeWordId]);

  useEffect(() => {
    if (isMounted.current) {
      onAuthStateChanged(auth, (user) => {
        setFormData({
          ...formData,
          useRef: user.uid,
        });
      });
    } else {
      navigate("/signin");
    }
  }, []);

  if (loading) {
    return <Spinner />;
  }

  const onChangeeng = (e) => {
    setFormData((prev) => ({ ...prev, eng: e.target.value }));
  };

  const onChangeita = (e) => {
    setFormData((prev) => ({ ...prev, ita: e.target.value }));
  };

  // ... Rest of the code, including onSubmit and onChangeHandler
  const onSubmit = async (e) => {
    e.preventDefault();
  
    // Rest of your onSubmit logic
  
    // Example: Update the data in Firestore
    const formDataCopy = {
      ...formData,
      timestamp: serverTimestamp(),
    };
    const docRef = await updateDoc(
      doc(db, "TypeWords", params.typeWordId),
      formDataCopy
    );
    toast.success("TypeWords Updated!");
    setLoading(false);
    navigate(`/${formDataCopy}/${docRef.id}`);
  };
  
  return (
    <Layout>
      <div className={styles.container}>
        <div>
          <form onSubmit={onSubmit}>
            <div className="d-flex flex-row mt-4">
              {/* <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  value="Type Words"
                  onChange={() => {}}
                  defaultChecked
                  name="type"
                  id="type"
                />
                <label className="form-check-label" htmlFor="typeWords">
                  Edit Type Words
                </label>
              </div> */}
            </div>
            <div className={styles.inputcontainer}>
              <label htmlFor="eng" className="form-label">
                English Words
              </label>
              <input
                type="text"
                className={styles.inputfild}
                id="eng"
                value={formData.eng}
                onChange={onChangeeng}
                required
              />
            </div>
            <div className={styles.inputcontainer}>
              <label htmlFor="ita" className="form-label">
                Italian Words
              </label>
              <input
                type="text"
                className={styles.inputfild}
                id="ita"
                value={formData.ita}
                onChange={onChangeita}
                required
              />
            </div>
            <div className="mb-3">
              <input
                disabled={!eng || !ita}
                className="btn btn-primary w-100"
                type="submit"
                value="Edit TypeWords"
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default TypeWordsEditListing;
