// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDAsFecfPt93UpiI1gy5n9szmSNFS80U24",
    authDomain: "consulux-66661.firebaseapp.com",
    projectId: "consulux-66661",
    storageBucket: "consulux-66661.appspot.com",
    messagingSenderId: "764719532382",
    appId: "1:764719532382:web:0f77241264cbfe6a9aa7cf"
 // addd your key and configuration details
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore();
