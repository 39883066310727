import React, { useState, useEffect } from "react";
// import Layout from "../components/Layout/Layout";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebase.config";
import { getAuth } from "firebase/auth";
import { useNavigate,  useParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import SwipeCore, { EffectCoverflow,  Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import styles from "./listing.module.css";
// import { Hidden } from "@mui/material";

//config
SwipeCore.use([EffectCoverflow, Pagination]);

const Listing = () => {
  const [listing, setListing] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const auth = getAuth();

  useEffect(() => {
    const fetchListing = async () => {
      const docRef = doc(db, "listings", params.listingId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log(docSnap.data());
        setListing(docSnap.data());
        setLoading(false);
      }
    };
    fetchListing();
  }, [params.listingId]);

  if (loading) {
    return <Spinner />;
  }

  return (
    
      <div className={styles.show}>
            <div className={styles.bodys}>                            
             <div className={styles.container}>
                    <div className={styles.card}>
                        <div class={styles.imgbx} >
                        {listing.imgUrls === undefined ? (
                          <Spinner />
                        ) : (
                          <Swiper
                            effect={"coverflow"}
                            grabCursor={true}
                            centeredSlides={true}
                            slidesPerView={1}
                            coverflowEffect={{
                              rotate: 50,
                              stretch: 0,
                              depth: 100,
                              modifier: 1,
                              slideShadows: true,
                            }}
                            pagination={true}
                            className="mySwipe"
                          >
                            {listing.imgUrls.map((url, index) => (
                              <SwiperSlide key={index}>
                                <img
                                  src={listing.imgUrls[index]}
                                  // height={400}
                                  // width={800}
                                  alt={listing.name}
                                />
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        )}
                            {/* {blog.Image} */}
                        

                    </div>
                    <div className={styles.content}>
                            <h1>
                                {/* <span><b>Title :</b></span> */}
                                <span>{listing.name}</span>
                                <div className={styles.underline} />
                            </h1>
                            {/* <p><pre><b>Body :</b></pre></p> */}
                            <p><pre>{listing.body}</pre></p>
                        </div>
                </div>
            </div>
        </div> </div>
    
  );
};

export default Listing;
